.animatedText {
  display: inline-block;
  padding: 0;
  border-right: 1px solid #414141;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  font-size: 55px;
}

@media screen and (max-width: 768px) {
.animatedText{
  font-size: 30px !important;
}
}


@media screen and (min-width: 768px) {
  .myInlineStyle{
    margin-bottom: 120px !important;
  }
  }

  @media screen and (max-width: 992px){
    .navbar {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        background: #131313;
        margin-top: 0px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        display: block;
        z-index: 4;
    }
    }